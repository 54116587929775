<template>
  <div>

    <el-row>
      <el-col :span="10">
        <h4>{{sideEffectTitle}}</h4>
      </el-col>
      <el-col :span="14" class="text-right">
          <el-button type="primary" @click="onSaveSideEffect({resetForm: true})">Neue Komplikation</el-button>
          <el-button type="primary" @click="onSaveSideEffect({resetForm: false})">{{sideEffectSaveButtonTitle}}</el-button>
      </el-col>
    </el-row>

    <el-tabs class="mt-3">
      <el-tab-pane label="Komplikation">

        <el-row class="mt-4">
          <el-col :span="8">
            <h6>NW oder Komplikation</h6>
            <el-select size="medium" class="w-100" v-model="currentSideEffect.side_effect_type_id" filterable>
              <el-option :value="null">-- Keine Auswahl --</el-option>
              <el-option
                v-for="item in sideeffecttypes"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10" :offset="1">
            <h6>Kausalität</h6>
            <el-select size="medium" class="w-100" v-model="currentSideEffect.causality_id" filterable>
              <el-option :value="null">-- Keine Auswahl --</el-option>
              <el-option
                v-for="item in causalities"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-4">
          <el-col :span="8">
            <h6>Beginn</h6>
            <el-date-picker
              class="w-100"
              v-model="currentSideEffect.date_start"
              type="date"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
              placeholder="Datum auswählen">
            </el-date-picker>
          </el-col>

          <el-col :span="15" :offset="1">
            <h6>Kausalität Status</h6>
            <el-radio-group v-model="currentSideEffect.causal">
              <el-radio label="3" >Kein Zusammenhang</el-radio>
              <el-radio label="0" >Unsicher</el-radio>
              <el-radio label="1" >Wahrscheinlich</el-radio>
              <el-radio label="2" >Gesichert</el-radio>
            </el-radio-group>
          </el-col>

        </el-row>

        <el-row class="mt-4">
          <el-col :span="8">
            <h6>Ende</h6>
            <el-date-picker
              class="w-100"
              v-model="currentSideEffect.date_end"
              type="date"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
              placeholder="Datum auswählen">
            </el-date-picker>
          </el-col>
          <el-col :span="10" :offset="1">

            <h6>Protokoll</h6>
            <el-select size="medium" class="w-100" v-model="currentSideEffect.patient_therapy_id" filterable>
              <el-option :value="null">-- Keine Auswahl --</el-option>
              <el-option
                v-for="item in allTherapies"
                :key="item.id"
                :label="$filters.germanDate(item.date_start) + (item.meta.protocol ? (', ' + (item.meta.protocol.title ? item.meta.protocol.title : item.meta.protocol.description)) : '')"
                :value="item.id">
              </el-option>
            </el-select>

            <div v-if="currentProtocol" class="mt-4">
              <h6>Betroffende Medikamente</h6>
                <el-checkbox-group v-model="currentSideEffect.local_drugs" v-if="currentProtocol.drugs">
                  <el-checkbox v-for="drug in currentProtocol.drugs" :key="drug.id" :label="drug.id">
                    {{drug.title}}
                  </el-checkbox>
                </el-checkbox-group>
            </div>
          </el-col>
        </el-row>

        <el-row class="mt-4">
          <el-col :span="8">
            <h6>CTCAE Vers. 5.0</h6>
            <el-select size="medium" class="w-100" v-model="currentSideEffect.ctcae50_id" filterable>
              <el-option :value="null">-- Keine Auswahl --</el-option>
              <el-option
                v-for="item in ctcae50"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10" :offset="1">
            <h6>CTCAE Grad</h6>
            <el-select size="medium" class="w-100" v-if="currentSideEffect.ctcae50_id" v-model="currentSideEffect.ctcae50_grade" filterable>
              <el-option :value="null">-- Keine Auswahl --</el-option>
              <el-option
                v-for="item in ctcae50_grades"
                :key="item.level"
                :label="(item.level + ': ' + item.title)"
                :value="item.level">
              </el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-4">
          <el-col :span="8">
            <h6>SAE Komplikation ?</h6>
            <el-radio-group v-model="currentSideEffect.sae">
              <el-radio label="1" >Ja</el-radio>
              <el-radio label="0" >Nein</el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="10" :offset="1">

            <h6>Ergebnis</h6>
            <el-radio-group v-model="currentSideEffect.result">
              <el-radio label="0" >Heilung</el-radio>
              <el-radio label="3" >Andauernd</el-radio>
              <el-radio label="1" >bleibender Schaden</el-radio>
              <el-radio label="2" >Tod</el-radio>
            </el-radio-group>

          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane>
        <template #label>
          Beschreibungen / Befunde <el-tag v-if="currentSideEffect.progress" size="small" type="warning">{{currentSideEffect.progress.length}}</el-tag>
        </template>

        <el-row class="mt-4" >
          <el-col :span="4">
            <h6>Befund-Datum</h6>
          </el-col>
          <el-col :span="7"  :offset="1">
            <h6>Beschreibung / Befund</h6>
          </el-col>
          <el-col :span="6"  :offset="1">
            <h6>Maßnahmen / Therapie</h6>
          </el-col>
        </el-row>

        <div v-for="progress in currentSideEffect.progress" :key="progress.id">
          <el-row class="mt-2" v-if="!progress.deleted">
            <el-col :span="4">
              <el-date-picker
                class="w-100"
                v-model="progress.date"
                type="date"
                format="DD.MM.YYYY"
                value-format="YYYY-MM-DD"
                placeholder="Datum auswählen">
              </el-date-picker>
            </el-col>
            <el-col :span="7"  :offset="1">
              <el-input type="textarea" v-model="progress.description"></el-input>
            </el-col>
            <el-col :span="6"  :offset="1">
              <el-input type="textarea" v-model="progress.action"></el-input>
            </el-col>
            <el-col :span="2" :offset="1">
              <el-button @click="onBtnDeleteProgressClick(progress)" size="mini" type="danger" icon="el-icon-delete"></el-button>
            </el-col>
          </el-row>
        </div>
        <el-button type="primary" class="mt-3" @click="onBtnNewProgressClick" size="mini" icon="el-icon-circle-plus">Beschreibung / Befund hinzufügen</el-button>

      </el-tab-pane>
      <el-tab-pane>
        <template #label>
          Krankenhausaufenthalte <el-tag v-if="currentSideEffect.patient_hospitalization" size="small" type="warning">{{currentSideEffect.patient_hospitalization.length}}</el-tag>
        </template>

        <el-row class="mt-1">
          <el-col :span="24">
            <hospitalization-table :data="currentSideEffect.patient_hospitalization" :deleteable="true" @on-row-delete="onDeleteHospitalizationFromSideEffect"></hospitalization-table>
          </el-col>
        </el-row>

        <el-row class="mt-4" v-if="currentPatient && selectableHospitalization.length > 0">
          <el-col :span="10">
            Krankenhausaufenthalt zuweisen:
            <el-select placeholder="Krankenhausaufenthalt auswählen" size="medium" class="ml-3" v-model="selectedHospitalization" filterable>
              <el-option
                v-for="item in selectableHospitalization"
                :key="item.id"
                :label="($filters.germanDate(item.date_start) + ', ' + (item.cause))"
                :value="item">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="mt-4" v-else>
          <el-col :span="24">
            Es sind keine (weiteren) Krankenhausaufenthalt zur Zuweisung vorhanden
          </el-col>
        </el-row>

      </el-tab-pane>
    </el-tabs>



    <el-row class="mt-5">
      <el-col :span="12">
        <el-popconfirm
          v-if="currentSideEffect.id > 0"
          :title="'Sie möchten diese Komplikation vom ' + $filters.germanDate(currentSideEffect.date_start) + ' löschen?'"
          confirmButtonText='Ja, löschen'
          confirmButtonType="danger"
          cancelButtonText='Nein'
          iconColor="red"
          @confirm="onDeleteSideEffect">
          <template #reference>
            <el-button type="danger" icon="el-icon-delete">Komplikation löschen</el-button>
          </template>
        </el-popconfirm>
      </el-col>
      <el-col :span="12" class="text-right">
        <el-button type="primary" @click="onSaveSideEffect({resetForm: true})">Neue Komplikation</el-button>
        <el-button type="primary" @click="onSaveSideEffect({resetForm: false})">{{sideEffectSaveButtonTitle}}</el-button>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
const _ = require('lodash');
import HospitalizationTable from "@/components/parts/HospitalizationTable";

export default {
  name: "PatientDiagnosisForm",
  props: [],
  components: { HospitalizationTable },
  data() {
    return {
      newSideEffectTemplate: {
        date_start: moment().format("YYYY-MM-DD") + " 12:00:00",
        meta: {},
        sae: "0",
        patient_hospitalization: [],
        local_drugs: [],
        progress: [
          {
            id: 0,
            date:  moment().format("YYYY-MM-DD") + " 12:00:00",
          }
        ]
      },
      currentSideEffect: {},
      editMode: false,
      allTherapies: [],
      selectableHospitalization: [],
      selectedHospitalization: null,
      currentProtocol: null
    }
  },
  mounted: async function () {

    if(this.$route.params.patient_sideeffect_id) {
      this.editMode = true
    }

    this.currentSideEffect = _.cloneDeep(this.newSideEffectTemplate)

    await this.getSideEffectTypes()
    await this.getCausalities()

    if(this.$route.params.patient_sideeffect_id) {
      await this.loadSideEffectOfPatient()
    }
  },
  computed: {
    ...mapGetters('patients', ['currentPatient']),
    ...mapGetters('sideeffecttypes', ['sideeffecttypes']),
    ...mapGetters('causalities', ['causalities']),
    ...mapGetters('protocols', ['protocols']),
    sideEffectTitle() {
      return (this.editMode ? 'Komplikation' : 'Komplikation speichern')
    },
    sideEffectSaveButtonTitle() {
      return 'Komplikation speichern'
    },
    ctcae50() {
      if(this.currentSideEffect.side_effect_type_id) {
        const sideeffecttype = _.find(this.sideeffecttypes, (o) => o.id == this.currentSideEffect.side_effect_type_id)
        return sideeffecttype.ctcae50
      }
      return []
    },
    ctcae50_grades() {
      if(this.currentSideEffect.ctcae50_id) {
        const ctcae = _.find(this.ctcae50, (o) => o.id == this.currentSideEffect.ctcae50_id)
        return ctcae.grades
      }
      return []
    }
  },
  watch: {
    '$route.params.patient_sideeffect_id': function (val) {
      if(val > 0) {
        this.loadSideEffectOfPatient()
      }
    },
    'currentSideEffect.patient_therapy_id': async function (patient_therapy_id, old_patient_therapy_id) {
      if(old_patient_therapy_id && old_patient_therapy_id != patient_therapy_id) {
        this.currentSideEffect.local_drugs = []
      }
      this.loadDrugsOfProtocol()
    },
    currentPatient: function(val) {
      this.allTherapies = {...val.therapies}
      this.selectableHospitalization = _.cloneDeep(this.currentPatient.hospitalization)
    },
    'selectedHospitalization': function(hospitalization) {

      if (hospitalization) {
        const exist = _.find(this.currentSideEffect.patient_hospitalization, (o) => o.id == hospitalization.id)
        if (exist) return

        let allHospitalization = _.cloneDeep(this.currentSideEffect.patient_hospitalization)
        allHospitalization.push(hospitalization)

        this.currentSideEffect.patient_hospitalization = allHospitalization
        this.selectedHospitalization = null
      }
    }
  },
  methods: {
    ...mapActions('patients', ['saveSideEffectToPatient', 'getSideEffectOfPatient', 'deleteSideEffectOfPatient']),
    ...mapActions('sideeffecttypes', ['getSideEffectTypes']),
    ...mapActions('causalities', ['getCausalities']),
    ...mapActions('protocols', ['getProtocols']),
    async loadSideEffectOfPatient() {
      this.editMode = true
      this.currentSideEffect = await this.getSideEffectOfPatient({patient_id: this.$route.params.id, patient_sideeffect_id: this.$route.params.patient_sideeffect_id})
      if(!this.currentSideEffect.progress || this.currentSideEffect.progress.length == 0) {
        this.onBtnNewProgressClick()
      }
      this.currentSideEffect.local_drugs = this.currentSideEffect.drugs ? this.currentSideEffect.drugs.map((d) => d.drug_id) : []
      await this.loadDrugsOfProtocol()
    },
    async loadDrugsOfProtocol() {
      const therapy = _.find(this.allTherapies, (t) => t.id == this.currentSideEffect.patient_therapy_id)
      this.currentProtocol = null
      if(therapy) {
        await this.getProtocols({ ids: therapy.protocol_id })
        this.currentProtocol = _.find(this.protocols, (p) => p.id == therapy.protocol_id)
      }
    },
    async onDeleteSideEffect() {
      if(this.currentSideEffect.id > 0) {
        const result = await this.deleteSideEffectOfPatient(this.currentSideEffect)

        if(result.status === 200) {
          this.$notify({
            message: 'Komplikation wurde gelöscht',
            type: 'success',
            position: 'bottom-left'
          });

          this.$router.push({ name: 'dashboard'})
        }
      else {
          this.$notify({
            message: 'Ein Fehler trat auf',
            type: 'error',
            position: 'bottom-left'
          });
        }
      }
    },
    async onSaveSideEffect(options) {

      if(!this.currentSideEffect.side_effect_type_id || this.currentSideEffect.side_effect_type_id === 0) {
        this.$notify({
          message: 'Wählen Sie bitte eine Komplikation aus',
          type: 'error',
          position: 'bottom-left'
        });
        return
      }
      if(!this.currentSideEffect.date_start) {
        this.$notify({
          message: 'Geben Sie bitte einen Beginn an',
          type: 'error',
          position: 'bottom-left'
        });
        return
      }

      this.currentSideEffect.date_start = moment(this.currentSideEffect.date_start).format("YYYY-MM-DD")
      this.currentSideEffect.date_end = this.currentSideEffect.date_end ? moment(this.currentSideEffect.date_end).format("YYYY-MM-DD") : null
      this.currentSideEffect.patient_id = this.currentPatient.id

      const result = await this.saveSideEffectToPatient(this.currentSideEffect)

      this.$notify({
        message: 'Komplikation wurde gespeichert',
        type: 'success',
        position: 'bottom-left'
      });

      if(options.resetForm) {
        this.editMode = false
        this.currentSideEffect = _.cloneDeep(this.newSideEffectTemplate)
        this.$router.push({ name: 'sideeffects'})
      }
      else {
        if(this.editMode) await this.loadSideEffectOfPatient()
        else {
          this.editMode = true
          this.$router.push({ name: 'edit_sideeffect', params: { patient_sideeffect_id: result.id }})
        }
      }

    },
    onBtnDeleteProgressClick(progress) {
      if(progress.id === 0) {
        this.currentSideEffect.progress = _.filter(this.currentSideEffect.progress, (h) => h.id !== 0)
      }
      else {
        progress.deleted = true
      }
    },
    onBtnNewProgressClick() {

      const exist = _.find(this.currentSideEffect.progress, (o) => o.id == 0)
      if(exist) return

      this.currentSideEffect.progress.push({
        id: 0,
        date: moment().format("YYYY-MM-DD")
      })
    },
    onDeleteHospitalizationFromSideEffect(hospitalization) {
      this.currentSideEffect.patient_hospitalization = _.filter(this.currentSideEffect.patient_hospitalization, (h) => h.id !== hospitalization.id)
    },
  }
}
</script>
<style scoped>
</style>
